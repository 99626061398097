import React, { memo } from 'react';
import classNames from 'classnames';

import FixtureListItem from './FixtureListItem';

import styles from './FixtureList.module.scss';

const FixtureList = ({ data, isLegacy }) => {
    if (data.length) {
        const daylessCount = data.filter((item) => item.is_no_day).length;
        console.log(daylessCount);

        return (
            <ul className={styles.root}>
                {data.map((fixture, index) => (
                    <li
                        key={fixture.id}
                        className={classNames(
                            styles.item,
                            index === daylessCount - 1 ? styles.itemSeparated : null
                        )}
                    >
                        <FixtureListItem data={fixture} isLegacy={isLegacy} />
                    </li>
                ))}
            </ul>
        );
    }

    return <div className={styles.root}>No fixtures</div>;
};

export default memo(FixtureList);
