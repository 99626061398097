import React, { createRef, Fragment, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PubSub from 'pubsub-js';

import { formatDate } from 'app/utility/formatters';

import FixtureList from 'app/components/FixtureList';

import styles from './FixtureMonthList.module.scss';

const getClosestTitle = (titles) =>
    titles.current.reduce(
        ([element, offset], cur) => {
            if (!cur.current) {
                return [element, offset];
            }

            const { top } = cur.current.getBoundingClientRect();
            return top >= 0 && offset < top ? [element, offset] : [cur.current, top];
        },
        [null, 0]
    );

const FixtureMonthList = ({ data, isLegacy }) => {
    const subRef = useRef(null);
    const activeTitle = useRef('');
    const titles = useRef([...Array(data.length)].map(() => createRef()));

    const applyTitle = () => {
        const text = getClosestTitle(titles)[0] ? getClosestTitle(titles)[0].innerText : null;

        if (text !== activeTitle.current) {
            activeTitle.current = text;
            PubSub.publish('@season/TITLE', text);
        }

        subRef.current = requestAnimationFrame(applyTitle);
    };

    useEffect(() => {
        subRef.current = requestAnimationFrame(applyTitle);

        return () => {
            cancelAnimationFrame(subRef.current);
        };
    }, []);

    return (
        <div className={styles.root}>
            {data.map(({ key, fixtures }, index) => (
                <Fragment key={key}>
                    <h3
                        ref={titles.current[index]}
                        className={classNames(
                            styles.title,
                            key === '-' ? styles.titleUnknown : null
                        )}
                    >
                        {key === '-' ? 'Date Not Known' : formatDate(key, 'MMMM YYYY')}
                    </h3>
                    <FixtureList data={fixtures} isLegacy={isLegacy} />
                </Fragment>
            ))}
        </div>
    );
};

export default FixtureMonthList;
