import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';

import classNames from 'classnames';

import { getSeasonName } from 'app/services/season';

import Container from 'app/components/Container';
import Link from 'app/components/Link';

import styles from './SeasonNavigation.module.scss';

const SeasonNavigation = ({ previous, next }) => {
    const [title, setTitle] = useState(null);

    useEffect(() => {
        const sub = PubSub.subscribe('@season/TITLE', (key, data) => {
            setTitle(data);
        });

        return () => {
            PubSub.unsubscribe(sub);
        };
    }, []);

    return (
        <nav className={styles.root}>
            <Container className={styles.container}>
                {title && <h2 className={styles.title}>{title}</h2>}
                {previous !== null && (
                    <Link
                        className={classNames(styles.link, styles.linkPrevious)}
                        to={`/${previous}`}
                    >
                        <span className={classNames(styles.linkTitle, styles.linkTitlePrevious)}>
                            {previous ? getSeasonName(previous) : '---'}
                        </span>
                        <small className={styles.linkDesc}>Prev Season</small>
                    </Link>
                )}
                {next !== null && (
                    <Link className={classNames(styles.link, styles.linkNext)} to={`/${next}`}>
                        <span className={styles.linkTitle}>
                            {next ? getSeasonName(next) : '---'}
                        </span>
                        <small className={styles.linkDesc}>Next Season</small>
                    </Link>
                )}
            </Container>
        </nav>
    );
};

SeasonNavigation.propTypes = {
    next: PropTypes.number,
    previous: PropTypes.number,
};

SeasonNavigation.defaultProps = {
    next: null,
    previous: null,
};

export default SeasonNavigation;
