const MONTH_JUNE = 5;

export const getSeasonName = (year) => `${year - 1} - ${year}`;

export const getCurrentSeason = () => {
    const date = new Date();
    const month = date.getMonth();
    const year = date.getFullYear();

    return month > MONTH_JUNE ? year + 1 : year;
};
