import React from 'react';
import PropTypes from 'prop-types';

import { getOrdinal } from 'app/utility';

import Container from 'app/components/Container';
import ResultSummary from 'app/components/ResultSummary';
import SeasonHeaderBorder from './SeasonHeaderBorder';

import styles from './SeasonHeader.module.scss';

const SeasonHeader = ({ data, fixtures }) => (
    <header className={styles.root}>
        <Container>
            <small className={styles.meta}>
                Trinity&#39;s {getOrdinal(data.year_count)} season
            </small>
            <h2 className={styles.title}>{data.name}</h2>
            {/*<ResultSummary data={fixtures} />*/}
        </Container>
        {/*<SeasonHeaderBorder data={fixtures} />*/}
    </header>
);

SeasonHeader.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        year_count: PropTypes.number,
    }).isRequired,
};

export default SeasonHeader;
