import React from 'react';
import PropTypes from 'prop-types';

import { formatNumber, formatCompetitionRound } from 'app/utility/formatters';

import FixtureGoals from 'app/components/Fixture/FixtureGoals';

import styles from './FixtureFooter.module.scss';

const FixtureFooter = ({ data, isLegacy }) => (
    <footer className={styles.root}>
        <dl className={styles.list}>
            <div className={styles.group}>
                <dt className={styles.assistive}>Competition</dt>
                <dd className={styles.def}>{formatCompetitionRound(data)}</dd>
            </div>

            {Boolean(data.attendance) && (
                <div className={styles.group}>
                    <dt className={styles.term}>Attendance</dt>
                    <dd className={styles.def}>{formatNumber(data.attendance)}</dd>
                </div>
            )}
        </dl>
        <FixtureGoals data={data.goals} isLegacy={isLegacy} />
    </footer>
);

FixtureFooter.propTypes = {
    data: PropTypes.shape({
        attendance: PropTypes.number,
        goals: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
    }).isRequired,
    isLegacy: PropTypes.bool,
};

FixtureFooter.defaultProps = {
    isLegacy: false,
};

export default FixtureFooter;
