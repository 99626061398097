import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './FixtureHeader.module.scss';

const FixtureHeader = ({ data }) => (
    <header className={styles.root}>
        <h3 className={classNames(styles.title, data.opponent ? null : styles.titleUnknown)}>
            {data.opponent || 'TBA'}
        </h3>
    </header>
);

FixtureHeader.propTypes = {
    data: PropTypes.shape({
        opponent: PropTypes.string,
    }).isRequired,
};

export default FixtureHeader;
