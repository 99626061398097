import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './LoadMessage.module.scss';

const LoadMessage = ({ isOffset }) => (
    <div className={classNames(styles.root, isOffset ? styles.rootOffset : null)}>Loading...</div>
);

LoadMessage.propTypes = {
    isOffset: PropTypes.bool,
};

LoadMessage.defaultProps = {
    isOffset: false,
};

export default LoadMessage;
