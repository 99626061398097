import React from 'react';
import PropTypes from 'prop-types';

import styles from './FixtureGoals.module.scss';

const getPenCount = (goals) => goals.filter((goal) => goal.is_penalty).length;

const parseGoals = (data) =>
    Object.values(
        data.reduce((acc, cur) => {
            if (!acc[cur.player_name]) {
                acc[cur.player_name] = [];
            }
            acc[cur.player_name].push(cur);

            return acc;
        }, {})
    ).map(
        (goals) =>
            `${goals[0].player_name} ${goals
                .map((goal) => `${goal.time || ''}${goal.is_penalty ? ' (P)' : ''}`)
                .join(', ')}`
    );

const parseLegacyGoals = (data) =>
    Object.values(
        data.reduce((acc, cur) => {
            if (!acc[cur.player_name]) {
                acc[cur.player_name] = [];
            }
            acc[cur.player_name].push(cur);

            return acc;
        }, {})
    ).map((goals) => {
        const count = goals.length;
        const penCount = getPenCount(goals);

        if (count === 1) {
            return `${goals[0].player_name}${penCount === 1 ? ' (P)' : ''}`;
        }

        return `${goals[0].player_name} (${count}${penCount > 0 ? `, ${penCount}P` : ''})`;
    });

const FixtureGoals = ({ data, isLegacy }) => {
    if (data && data.length) {
        return (
            <ul className={styles.root}>
                {(isLegacy ? parseLegacyGoals(data) : parseGoals(data)).map((goal) => (
                    <li className={styles.item} key={goal}>
                        {goal}
                    </li>
                ))}
            </ul>
        );
    }

    return null;
};

FixtureGoals.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
        })
    ),
    isLegacy: PropTypes.bool,
};

FixtureGoals.defaultProps = {
    data: null,
    isLegacy: false,
};

export default FixtureGoals;
