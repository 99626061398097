import React from 'react';

import styles from './SeasonHeaderBorder.module.scss';

const setColor = (result) => {
    if (result === 'd') {
        return '#658e97';
    }
    return result === 'w' ? '#57db6d' : '#ee3768';
};

const SeasonHeaderBorder = ({ data }) => {
    const results = data
        .reduce((acc, cur) => [...acc, ...cur.fixtures], [])
        .reduce((acc, cur, index) => {
            const last = acc[acc.length - 1];

            if (last && last.result === cur.result) {
                acc[acc.length - 1] = {
                    result: last.result,
                    count: last.count + 1,
                    offset: last.offset + 1,
                };
                return acc;
            }

            return [...acc, { result: cur.result, count: 1, offset: (last ? last.offset : 0) + 1 }];
        }, []);

    const width = 100 / results[results.length - 1].offset;

    return (
        <svg className={styles.root}>
            {results.map((result, index) => (
                <rect
                    key={index}
                    height="100%"
                    width={`${width * result.count + 0.1}%`}
                    y="0"
                    x={`${width * (result.offset - result.count)}%`}
                    className={styles.item}
                    fill={setColor(result.result)}
                />
            ))}
        </svg>
    );
};

export default SeasonHeaderBorder;
