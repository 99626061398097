import React, { memo } from 'react';
import classNames from 'classnames';

import { formatDate } from 'app/utility/formatters';

import FixtureHeader from './FixtureHeader';
import FixtureScore from './FixtureScore';
import FixtureScoreExtra from './FixtureScoreExtra';
import FixtureFooter from './FixtureFooter';

import styles from './Fixture.module.scss';

const Fixture = ({ data, isLegacy }) => (
    <article className={styles.root}>
        <span
            className={classNames(
                styles.date,
                data.is_no_date || data.is_no_day ? styles.dateUnknown : null
            )}
        >
            {data.is_no_date
                ? '(Date not known)'
                : formatDate(data.date, `${data.is_no_day ? '' : 'dddd Do'} MMMM`)}
            {data.is_no_day && ' (Specific day not known)'}
        </span>
        <div className={styles.content}>
            <FixtureHeader data={data} />
            <abbr
                title={data.venue_name}
                className={classNames(styles.venue, styles[`venue${data.venue_name}`])}
            >
                {data.venue_name[0] === 'U' ? '?' : data.venue_name[0]}
            </abbr>
            <FixtureScore data={data} />
        </div>
        <FixtureScoreExtra data={data} />
        <FixtureFooter data={data} isLegacy={isLegacy} />
        {data.notes ? <p className={styles.notes}>{data.notes}</p> : null}
    </article>
);

export default memo(Fixture);
