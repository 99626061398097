import React from 'react';
import PropTypes from 'prop-types';

import Scene from 'app/components/Scene';
import Container from 'app/components/Container';
import SeasonHeader from 'app/components/SeasonHeader';
import FixtureMonthList from 'app/components/FixtureMonthList';
import LoadMessage from 'app/components/LoadMessage';
import SeasonNavigation from 'app/components/SeasonNavigation';

const SeasonScene = ({ pageContext }) => {
    const season = pageContext.data;
    const { fixtures } = season;

    return (
        <Scene title={season.name}>
            <main>
                <SeasonHeader data={season} fixtures={fixtures} />
                <Container>
                    {fixtures === null ? (
                        <LoadMessage />
                    ) : (
                        <FixtureMonthList
                            data={fixtures}
                            isLegacy={season ? season.is_legacy : false}
                        />
                    )}
                </Container>
            </main>
            <SeasonNavigation previous={season.previous} next={season.next} />
        </Scene>
    );
};

SeasonScene.propTypes = {
    pageContext: PropTypes.shape({
        data: PropTypes.shape({
            name: PropTypes.string.isRequired,
            fixtures: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string,
                })
            ),
            is_legacy: PropTypes.bool,
            previous: PropTypes.number,
            next: PropTypes.number,
        }),
    }).isRequired,
};

export default SeasonScene;
