import React from 'react';

import styles from './FixtureScoreExtra.module.scss';

const FixtureScoreExtra = ({ data }) => {
    if (data.score_is_extra) {
        return (
            <small className={styles.root}>
                After extra time. {data.home_score} - {data.away_score} after 90 mins.
                {data.score_is_pens && (
                    <span>
                        <br />
                        {data.result === 'w' ? 'Won ' : 'Lost '}
                        {data.g_score_pens} - {data.o_score_pens} on penalties.
                    </span>
                )}
            </small>
        );
    }

    return null;
};

export default FixtureScoreExtra;
