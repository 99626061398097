import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './FixtureScore.module.scss';

const FixtureScore = ({ data }) => {
    if (data.home_score_full === null || data.away_score_full === null) {
        return <i className={styles.root} />;
    }

    return (
        <dl className={classNames(styles.root, styles[`root${data.result.toUpperCase()}`])}>
            <dt className={styles.term}>Home Score</dt>
            <dd className={styles.def}>{data.home_score_full}</dd>
            <dt className={styles.term}>Away Score</dt>
            <dd className={styles.def}>{data.away_score_full}</dd>
        </dl>
    );
};

FixtureScore.propTypes = {
    data: PropTypes.shape({
        home_score_full: PropTypes.number,
        away_score_full: PropTypes.number,
        result: PropTypes.string,
    }),
};

FixtureScore.defaultProps = {
    data: { home_score_full: null, away_score_full: null },
};

export default FixtureScore;
